<template>
	<div class="relative">
		<div class="flex justify-between items-center mb-1.5">
			<v-text>{{ arrayIndex + 1 }}. {{ props?.scene['job__job_scene__SCENES__sceneID::name_display'] }}</v-text>
			<v-text v-show="songId.length">
				<v-text-link @click="removeSong">
					{{ t('scene.removeSong') }}
				</v-text-link>
			</v-text>
		</div>
		<Container
			:key="String(arrayIndex) + song.songId"
			group-name="scenes"
			behaviour="move"
			@drop="onDropScene($event)"
			:get-child-payload="(i:number) => getSong()"
			:drop-placeholder="{className: `bg-zinc-900/10 rounded-md`}"
			:class="['sm:w-[310px] h-[72px] rounded-md z-50 min-h-[72px]', {'outline-dashed outline-2 outline-zinc-300': !songId.length}]"
			:should-accept-drop="shouldAcceptDrop"
			:onDragStart="onDragStart"
			:onDragEnd="onDragEnd">
			<Draggable
				class="sm:max-w-[310px] z-50 bg-white"
				v-if="songId.length">
				<SongResult 
					:scene="true"
					:class="['draggable-item', { 'opacity-50': dragging }]"
					:result="song"
					@play="playSong" />
			</Draggable>
		</Container>
		<v-text
			v-show="!songId.length" 
			class="absolute top-13 left-5">
			{{ t('songSelection.emptySong') }}
		</v-text>
	</div>
</template>

<script lang="ts">
	export default { name: 'SceneCard' } // name the component
</script>

<script setup lang="ts">
	// Components
	import SongResult from '../search/components/SongResult.vue'

	// Drag n drop
	import { 
		Container,
		Draggable
	} from 'vue3-smooth-dnd'

	// Events
	import EventBus from '@/events'

	// Language
	import {
		useI18n
	} from 'vue-i18n'

	// Queries
	import {
		getSongById
	} from '../search/queries'

	// Store
	import { 
		store as _jobStore
	} from '@/store/job'
	import { 
		localesStore
	} from '@/store/locales' 

	// Types
	import{
		FindParameters,
		Song
	} from '@/types'

	// Vue
	import {
		defineEmits,
		onMounted,
		ref,
		inject,
		watch
	} from 'vue'

	const {t} = useI18n()
	const jobStore = _jobStore()
	const _localesStore = localesStore()

	const props = defineProps({
		scene: {
			type: Object,
			default: null
		},
		arrayIndex: {
			type: Number
		}
	})

	const emit = defineEmits([
		'playSong'
	])

	const fetchS3File: any = inject('fetchS3File')
	const song = ref({} as Song)
	const songId = ref<string>(props?.scene['job__JOBS_SCENES__jobID::zctSongID'])

	watch(props, () => {
		songId.value = props?.scene['job__JOBS_SCENES__jobID::zctSongID']
	})


	const sceneObj = ref(props.scene)

	onMounted(async () => {

		if(songId?.value?.length) {
			try {
				const result = await fetchS3File(props?.scene['job__job_scene__job_song__SONGS__songID::zctAlbumImgPathS3'])
				song.value.artworkUrl = result
				song.value.songId = songId.value
				song.value.artist = props?.scene['job__job_scene__job_song__SONGS__songID::zctArtistDisplay']
				song.value.songName = props?.scene['job__job_scene__job_song__SONGS__songID::name_display']
				song.value.songFile = props?.scene['job__job_scene__job_song__song__SONGS_INFO__songID::s3JSONSong']
				song.value.artworkFile = props?.scene['job__job_scene__job_song__SONGS__songID::zctAlbumImgPathS3']
			}
			catch(e) {
				console.log(e)
			}
		}

		EventBus.on('change-language', async () => {
			await getSongBySongId(songId.value)
		})
	})

	const loading = ref<boolean>(false)

	/**
	 * @function getSongBySongId - Get the song record from FM
	 * @param {string} songId - The FM song Id you want to query
	 */
	async function getSongBySongId(songId: string) {
		loading.value = true

		const scriptParams = JSON.stringify({
			language: _localesStore.getFmLanguage
		})
		const params: FindParameters = {
			query: [
				{'_kp_songs_id': songId}
			],
			'script.prerequest': 'Set Language',
			'script.prerequest.param': scriptParams
		} 

		try {
			const result = await getSongById(params)
			const songRes = result?.response?.data[0]?.fieldData
			const songName = songRes.name_display
			const artistName = songRes.zctArtistDisplay

			song.value.songName = songName
			song.value.artist = artistName
		}
		catch(e) {
			console.log(e)
		}
		finally {
			loading.value = false
		}
	}

	// #region Drag and drop

	/**
	 * @function onDrop -  Called by any relevant container when drop is over
	 * @param dropResult - The event object returned after dragging element
	 */
	async function onDropScene(dropResult: any) {
		const { removedIndex, addedIndex, payload, element } = dropResult

		if(removedIndex === 0) removeSong()
		if(addedIndex !== 0) return

		try {
			song.value = payload.song
			songId.value = payload.song.songId
			sceneObj.value['job__JOBS_SCENES__jobID::zctSongID'] = payload.song.songId
			sceneObj.value['job__job_scene__job_song__SONGS__songID::zctAlbumImgPathS3'] = payload.song.artworkFile
			sceneObj.value['job__job_scene__job_song__SONGS__songID::zctArtistDisplay'] = payload.song.artist
			sceneObj.value['job__job_scene__job_song__SONGS__songID::name_display'] = payload.song.songName
			sceneObj.value['job__job_scene__job_song__song__SONGS_INFO__songID::s3JSONSong'] = payload.song.songFile
			jobStore.job.jobDetails.scenes[props?.arrayIndex] = sceneObj.value
		}
		catch(e) {
			console.log(e)
		}
	}

	/**
	 * @function shouldAcceptDrop - Determines the containers to which the drop is possible
	 */
	function shouldAcceptDrop(sourceContainerOptions, payload) {
		return !songId.value.length || payload.song.songId === song.value.songId ? true : false
	}

	// #endregion

	/**
	 * @function removeSong - Remove the currently applied song from the job scene
	 */
	function removeSong() {
		song.value = {} as Song
		sceneObj.value['job__JOBS_SCENES__jobID::zctSongID'] = ''		
		jobStore.job.jobDetails.scenes[props?.arrayIndex] = sceneObj.value
	}

	const dragging = ref(false)
	function onDragStart({ isSource, payload }) {
		if(!isSource) return
		// payload holds the item being dragged, find the index and apply class
		dragging.value = true
	}

	function onDragEnd() {
		// Remove the dragging class when drag ends
		dragging.value = false
	}

	function getSong() {
		return {song: song.value}
	}

	function playSong(nowPlaying: {
		artist: '',
		songName: '',
		albumName: '',
		genre: '',
		artworkUrl: '',
		signedUrl: ''
	}) {
		EventBus.trigger('play-song', nowPlaying)
	}
</script>