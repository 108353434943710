<template>
	<div>
		<!--Combobox-->
		<Combobox 
			v-if="searchable || filterable"
			as="div"
			:model-value="modelValue"
			:multiple="multiple"
			@update:model-value="value => onSelect(value)">
			<ComboboxLabel class="block text-sm font-medium text-gray-700">
				<span
					v-if="required && !(hideRequired)"
					class="text-sm text-red-500">
					*
				</span>
				<span class="align-start">
					{{ label }}
				</span>
			</ComboboxLabel>
			<div class="mt-1 relative">
				<ComboboxInput
					id="comboBoxInput"
					class="relative w-full rounded-md border border-gray-300 py-2 pl-3 pr-10 text-left shadow-sm focus:border-brand-500 focus:outline-none focus:ring-1 focus:ring-brand-500 sm:text-sm"
					:class="{
						'bg-gray-200 cursor-not-allowed pointer-events-none': disabled, 
						'bg-white cursor-default': !disabled,
						'border-0 pr-10 text-red-900 ring-1 ring-inset ring-red-300 placeholder:text-red-300 focus:ring-2 focus:ring-inset focus:ring-red-500': !_valid
					}"
					@focus="setPlaceholder"
					@blur="clearPlaceholder"
					autocomplete="none" 
					@change="updateQuery($event)"
					:display-value="(v) => Array.isArray(v) ? v.join(', ') : String(v)"
					:placeholder="_placeholder" />
				<ComboboxButton 
					class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
					<ChevronUpDownIcon 
						class="h-5 w-5 text-gray-400" 
						aria-hidden="true" 
						v-show="!disabled" />
				</ComboboxButton>
				<TransitionRoot
					leave="transition ease-in duration-100"
					leave-from="opacity-100"
					leave-to="opacity-0"
					@after-leave="query = ''">
					<ComboboxOptions
						class="absolute z-30 mt-1 w-full bg-white shadow-lg max-h-60 rounded-sm py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
						<div
							as="template"
							v-show="noFilteredOptions"
							class="relative cursor-default select-none py-2 px-4 text-gray-700">
							{{ noResultsPlaceholder }}
						</div>
						<ComboboxOption
							as="template"
							v-for="option in (filterable ? filteredOptions : options)" 
							:key="option.value" 
							v-slot="{ active, selected }"
							:value="option.value">
							<li
								:class="[active ? 'text-white bg-brand-500' : 'text-gray-900', 'cursor-default select-none relative py-2 pl-3 pr-9']">
								<span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">
									{{ option.label }}
								</span>
								<span
									v-if="selected"
									:class="[active ? 'text-white' : 'text-brand-500', 'absolute inset-y-0 right-0 flex items-center pr-4']">
									<CheckIcon
										class="h-5 w-5"
										aria-hidden="true" />
								</span>
							</li>
						</ComboboxOption>
					</ComboboxOptions>
				</TransitionRoot>
			</div>
		</Combobox>

		<!--Listbox-->
		<Listbox
			v-else
			as="div"
			:model-value="modelValue"
			:multiple="multiple"
			@update:model-value="value => onSelect(value)">
			<ListboxLabel
				:aria-label="ariaLabel"
				class="block text-sm font-medium text-gray-700 min-h-[1.25rem]">
				<span>
					{{ label }}
				</span>
				<span
					v-if="required && !(hideRequired)"
					class="text-sm text-red-500">
					*
				</span>
			</ListboxLabel>
			<div class="mt-1 relative">
				<ListboxButton
					id="listBoxButton" 
					class="relative w-full rounded-md border border-gray-300 py-2 pl-3 pr-10 text-left shadow-sm focus:border-brand-500 focus:outline-none focus:ring-1 focus:ring-brand-500 sm:text-sm"
					:class="{
						'bg-gray-200 cursor-not-allowed pointer-events-none': disabled, 
						'bg-white cursor-default': !disabled,
						'border-0 pr-10 text-red-900 ring-1 ring-inset ring-red-300 placeholder:text-red-300 focus:ring-2 focus:ring-inset focus:ring-red-500': !_valid
					}">
					<span
						class="block truncate"
						:class="{'text-gray-600': !modelLabel}">{{ modelLabel || placeholder }}</span>
					<span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
						<XCircleIcon 
							class="h-5 w-5 text-red-500 hover:text-red-700 pointer-events-auto" 
							aria-hidden="true" 
							v-show="!disabled && modelLabel"
							v-if="clearable"
							@click.stop="clear" />
						<ChevronUpDownIcon
							class="h-5 w-5 text-gray-400"
							aria-hidden="true"
							v-show="!disabled" />
					</span>
				</ListboxButton>

				<transition
					leave-active-class="transition ease-in duration-100"
					leave-from-class="opacity-100"
					leave-to-class="opacity-0">
					<ListboxOptions
						class="absolute z-30 mt-1 w-full bg-white shadow-lg max-h-60 rounded-sm py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
						<ListboxOption
							as="template"
							:key="option.value" 
							v-for="option in options" 
							v-slot="{ active, selected }"
							:value="option.value">
							<li
								:class="[active ? 'text-white bg-brand-500' : 'text-gray-900', 'cursor-default select-none relative py-2 pl-3 pr-9']">
								<span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">
									{{ option.label }}
								</span>
								<span
									v-if="selected"
									:class="[active ? 'text-white' : 'text-brand-500', 'absolute inset-y-0 right-0 flex items-center pr-4']">
									<CheckIcon
										class="h-5 w-5"
										aria-hidden="true" />
								</span>
							</li>
						</ListboxOption>
					</ListboxOptions>
				</transition>
			</div>
		</Listbox>
		<p
			v-show="!_valid"
			class="mt-2 text-sm text-red-600">
			{{ props.required && !props.modelValue ? requiredError : validationError }}
		</p>
	</div>
</template>

<script setup lang="ts">
// #region imports
	import {
		ref,
		computed,
		defineExpose,
		nextTick,
		watch,
		PropType
	} from 'vue'
	
	import {
		Combobox,
		ComboboxButton,
		ComboboxInput,
		ComboboxLabel,
		ComboboxOption,
		ComboboxOptions,
		Listbox,
		ListboxButton,
		ListboxLabel,
		ListboxOption,
		ListboxOptions,
		TransitionRoot
	} from '@headlessui/vue'

	import {
		CheckIcon,
		ChevronUpDownIcon
	} from '@heroicons/vue/24/solid'
	// #endregion


	// #region setup
	const emit = defineEmits([
		'update:modelValue'
	])

	const props = defineProps({
		modelValue: {
			type: [String, Number, Array],
			default: null
		},
		clearable: {
			type: Boolean,
			default: false
		},
		disabled: {
			type: Boolean,
			default: false
		},
		filterable: {
			type: Boolean,
			default: false
		},
		filterPlaceholder: {
			type: String,
			default: "Type to filter list..."
		},
		label: {
			type: String,
			default: ''
		},
		multiple: {
			type: Boolean,
			default: false
		},
		noResultsPlaceholder: {
			type: String,
			default: "Nothing found."
		},
		options: {
			type: Array as PropType<Array<{label: string,value: string}>>,
			default: () => []
		},
		placeholder: {
			type: String,
			default: "None selected"
		},
		required: {
			type: Boolean,
			default: false
		},
		requiredError: {
			type: String,
			default: "This field is required."
		},
		searchable: {
			type: Boolean,
			default: false
		},
		searchMethod: {
			type: Function,
			default: () => {return}
		},
		searchPlaceholder: {
			type: String,
			default: "Type to search..."
		},
		valid: {
			type: Boolean,
			default: true
		},
		validationError: {
			type: String,
			default: "This field is not valid."
		},
		hideRequired: {
			type: Boolean,
			default: false
		},
		ariaLabel: {
			type: String,
			default: ''
		}
	})
	// #endregion


	// #region editing model value
	/**
	 * Text to display in the select box. Formats multiple selections as a comma separated string.
	 * @yields {string}
	 */
	const modelLabel = computed(() => {
		if(Array.isArray(props.modelValue)) {
			return props.modelValue.join(', ')
		}
		else{
			return props.modelValue
		}
	})

	/**
	 * Updates the bound value of the select menu
	 * @function onSelect
	 * @param {Object} option - the selected option of the select menu
	 */
	async function onSelect(value: any) {
		emit('update:modelValue', value)
		await nextTick()
		validate()
		return
	}

	/**
	 * Clears the selected value
	 * @function clear
	 */
	async function clear() {
		if(Array.isArray(props.modelValue)) {
			emit('update:modelValue', [])
		}
		else {
			emit('update:modelValue', '')
		}
		await nextTick()
		return
	}
	// #endregion

	// #region validation
	const _valid = ref(props.valid)
	watch(props, () => {
		_valid.value = props.valid
	})
	function validate() {
		if((props.required && !props.modelValue) || props.valid === false) {
			_valid.value = false
		}
		else if(props.required && props.modelValue) {
			_valid.value = true
		}
	}
	// #endregion


	// #region filtering
	const query = ref('')

	function updateQuery(e) {
		query.value = e.target.value
		if(props.searchable) {
			props.searchMethod(query.value)
		}
	}

	const _placeholder = ref(props.placeholder)

	/**
     * Updates the placeholder text when filtering or searching and the combobox is focused
     * @function setPlaceholder
     */
	function setPlaceholder() {
		if(props.filterable) _placeholder.value = props.filterPlaceholder
		else if(props.searchable) _placeholder.value = props.searchPlaceholder
	}

	/**
     * Clears the placeholder text when focus is lost on the combobox
     * @function setPlaceholder
     */
	function clearPlaceholder() {
		_placeholder.value = props.placeholder
	}

	/**
	 * List of options filtered by filter text
	 * @yields {array}
	 */
	const filteredOptions = computed(() => {
		return query.value === '' ? props.options : props.options.filter((option: any) => {
			return !props.filterable || !query.value || option.label.toLowerCase().replace(/\s+/g, '').includes(query.value.toLowerCase().replace(/\s+/g, '')) == true
		})
	})

	/**
	 * If no options remain when filtered by filter text
	 * @yields {boolean}
	 */
	const noFilteredOptions = computed(() => {
		return filteredOptions.value.length === 0 && query.value !== ''
	})
	// #endregion

	defineExpose({
		validate
	})

</script>
