<template>
	<component
		:is="layout">
		<router-view :key="route.fullPath" />
	</component>
</template>

<script setup lang="ts">
	// api
	import {
		getMultilinguals,
		getValueLists
	} from './queries'

	// Auth
	import { 
		Auth
	} from '@/api/Auth'

	// router
	import {
		useRoute
	} from 'vue-router'

	// store
	import { 
		localesStore
	} from '@/store/locales'

	// store
	import { 
		store as _userStore
	} from '@/store/user'
	import { 
		store as _valueListStore
	} from '@/store/valueLists'
	import { 
		store as _jobStore
	} from '@/store/job'

	// type
	import { 
		ValueList
	} from '@/types'


	// vue
	import {
		computed,
		onMounted,
		ref
	} from 'vue'
	
	// import MainLayout from './layouts/MainLayout.vue'
	import SidebarWithHeader from './layouts/Sidebar/SidebarWithHeader.vue'
	import PageFooter from '@/components/PageFooter.vue'

	const route = useRoute()
	const store = localesStore()
	const valueListStore = _valueListStore()
	const userStore = _userStore()
	const jobStore = _jobStore()

	const layout = computed(() => {
		return route.meta.layout || SidebarWithHeader
	})
	const loading = ref(true)

	// load translations from FM
	onMounted(async () => {
		try {
			if(!userStore.user.loggedIn) {
				return
			}
			
			loading.value = true
			// fetch translations
			let res = await getMultilinguals()
			let vlRes = await getValueLists()

			vlRes.response.valueLists.forEach((list: ValueList) => {
				let lowerName = list.name.toLowerCase()
				let key = lowerName.split("_").reduce((s, c) => s + (c.charAt(0).toUpperCase() + c.slice(1)))
				const indexToRemove = list.values.findIndex(v => v.value === "-")
				if(indexToRemove > -1) list.values.splice(indexToRemove, 1)
				valueListStore.valueList[key] = list.values.map((option) => {					
					return {
						value: option.value,
						label: option.value
					}					
				})
			})

			store.locales.en = JSON.parse("{" + res.response.data[0].fieldData.zsJsonEn.replace(/\r/g, ',') + "}")
			store.locales.ja = JSON.parse("{" + res.response.data[0].fieldData.zsJsonKanji.replace(/\r/g, ',') + "}")
			store.locales.kana = JSON.parse("{" + res.response.data[0].fieldData.zsJsonKana.replace(/\r/g, ',') + "}")
		}
		catch(e) {
			console.log(e)
		}
		finally {
			loading.value = false
		}
	})

	onMounted(() => {
		document.addEventListener("keypress", resetTimer, false)
		document.addEventListener("mousemove", resetTimer, false)
		document.addEventListener("mousedown", resetTimer, false)
		document.addEventListener("touchmove", resetTimer, false)
		
		startTimer()
	})

	// Timeout time is in ms => {{x}} minutes x 60 x 1000
	const timeOutTime = ref(600000) // 10 minutes
	const timeoutID = ref(null)

	/**
	 * To start the inactivity timer
	 * @function startTimer
	*/
	function startTimer() { 
		// setTimeout returns an ID (can be used to start or clear a timer)
		timeoutID.value = setTimeout(handleInactive, timeOutTime.value)
	}

	/**
	 * To log the user out after inactivity
	 * @function handleInactive
	*/
	function handleInactive() {
		if(jobStore.job.loggedIn) {
			Auth.signOut()
		}
	}

	/**
	 * To reset the inactivity timer
	 * @function resetTimer
	*/
	function resetTimer() { 
		clearTimeout(timeoutID.value)
		startTimer()
	}

	// Logout after closing window
	window.addEventListener('beforeunload', (e) => {
		e.preventDefault()
		if(!e.defaultPrevented) {
			Auth.signOut()
		}
	})
</script>